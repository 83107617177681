import React, { useState, useContext } from 'react';
import styles from './styles.module.css';
import Loader from '../../utils/Loading';
import ShopSettingsService from '../../../services/ShopSettingsService';
import { AlertMessagesContext } from 'react-alert-messages';
import { useEffect } from 'react';
import DataTable from '../../utils/DataTable/index';
import Info from '../../utils/Alert/Info';
import { Button, TextField, Grid } from '@mui/material';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import EditPaymentModeDialog from '../../popups/EditPaymentModeDialog';

function PaymentModes() {
  const { postAlertMessage } = useContext(AlertMessagesContext);
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [paymentModes, setPaymentModes] = useState([]);
  const [newMode, setNewMode] = useState('');
  const [selectedPaymentModeForEdit, setSelectedPaymentModeForEdit] = useState(null);

  const getPaymentModes = async () => {
    setLoadingIndicator(true);
    try {
      const params = { all: true };
      const res = await ShopSettingsService.getPaymentModes(params);
      setPaymentModes(res);
    } catch (error) {
      postAlertMessage({ text: error?.message, type: 'fail' });
    }
    setLoadingIndicator(false);
  };

  const updatePaymentModeStatus = (mode) => {
    const data = { is_enabled: !mode?.is_enabled };
    updatePaymentModes(mode, data);
  };


  const updatePaymentModes = async (mode, data) => {
    setLoadingIndicator(true);
    try {
      await ShopSettingsService.updatePaymentModes(mode?.id, data);
      postAlertMessage({
        text: 'Payment modes updated successfully',
        type: 'success',
      });
      getPaymentModes();
    } catch (error) {
      postAlertMessage({ text: error?.message, type: 'fail' });
    }
    setLoadingIndicator(false);
  };

  const createPaymentMode = async () => {
    setLoadingIndicator(true);
    try {
      if (newMode.length) {
        await ShopSettingsService.createPaymentMode({ name: newMode });
        postAlertMessage({
          text: 'Payment modes added successfully',
          type: 'success',
        });
        setNewMode('');
        getPaymentModes();
      } else {
        postAlertMessage({
          text: 'Please provide a valid payment mode name',
          type: 'fail',
        });
      }
    } catch (error) {
      postAlertMessage({ text: error?.message, type: 'fail' });
    }
    setLoadingIndicator(false);
  };

  const headerData = [
    {
      label: 'Name',
      id: 'name',
      type: 'text',
    },
    {
      label: 'Status',
      id: 'staus',
      type: 'callback',
      viewRender: (obj) => {
        return obj.is_enabled ? 'Active' : 'Deactive';
      },
    },
    {
      label: 'Edit',
      id: 'edit',
      type: 'button',
      title: 'Edit',
      clickHandler: setSelectedPaymentModeForEdit,
    },
    {
      id: 'deleteBtn',
      type: 'callback',
      align: 'right',
      viewRender: (obj) => (
        <Button
          onClick={(e) => {
            updatePaymentModeStatus(obj);
            e.stopPropagation();
          }}
          variant="contained"
          size="small"
          color="primary"
        >
          {obj.is_enabled ? 'Deactivate' : 'Activate'}
        </Button>
      ),
    },
  ];

  useEffect(() => {
    getPaymentModes();

    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles.contentLayout}>
      <Loader isOpen={loadingIndicator} />
      <div className={styles.settingsGrid}>
        <div className={styles.BillHeaderDiv}>
          <span className={styles.pageHead}>Create payment mode</span>
        </div>
        <Grid container mb={2}>
          <Grid item xs={6}>
            <TextField
              size="small"
              fullWidth
              variant="outlined"
              color="primary"
              label="Enter payment mode name"
              inputProps={{
                style: {
                  padding: 10,
                },
              }}
              value={newMode}
              onChange={(e) => setNewMode(e.target.value)}
            />
          </Grid>
          <Grid item xs={6} textAlign={'end'}>
            <Button
              className={styles.fieldButtons}
              variant="contained"
              size="medium"
              style={{ backgroundColor: '#ff851b' }}
              onClick={createPaymentMode}
            >
              <NoteAddIcon className={styles.addBtnIcon} />
              Create
            </Button>
          </Grid>
        </Grid>
        {paymentModes.length ? (
          <DataTable columns={headerData} rows={paymentModes} />
        ) : (
          <Info
            title={'No payments modes to list'}
            content={'You have no payments modes to list. Please create a new payment mode'}
          />
        )}
      </div>
      {selectedPaymentModeForEdit && (
        <EditPaymentModeDialog
          handleClose={() => {
            setSelectedPaymentModeForEdit(null);
          }}
          paymentMode={selectedPaymentModeForEdit}
          updatePaymentModes={updatePaymentModes}
        />
      )}
    </div>
  );
}

export default PaymentModes;
